jQuery(document).ready(function() {
    jQuery(".mobile-icon").on("click touchstart", function (event) {
            event.preventDefault();
            event.stopPropagation();
            jQuery("body").toggleClass("open-menu");
        });

         jQuery(".site-header .navigation").on("click touchstart", function (event) {
            event.stopPropagation();
        });

        jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

           if(!jQuery(this).parent().hasClass("focus") && jQuery(this).parent().has("ul").length > 0)
           {
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("focus").siblings().removeClass("focus");
           }
          });
});
